import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';
import Link from 'components/ui/link';

import cover from 'img/blog/like-a-pro.jpg';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        Today’s business world is competitive and is growing dynamically. To beat your competitors
        you should have an outstanding website which also would be easy to find. When we talk about
        optimization very often marketers minds go to search engine optimization, nevertheless we
        can not forget about user experience.
        <br />
        It is not about how it looks. UX is about how it works. Professional{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://uxstudioteam.com/ux-consulting/"
        >
          UX Consultants
        </Link>{' '}
        could help you run a holistic overview of your product/service. They examine business model,
        value proposition, user flows, UI screens, conduct user research to identify your target
        audience pains and needs. All the way down to improve your user experience and meet your
        business goals. Learn more about best website creation practices for greater business
        success. Find out how to do it like a pro and boost your website’s performance!
      </p>
      <br />

      <h2>Make your website useful</h2>
      <p>
        Let’s start with user experience which is crucial for your visitors. The more intuitive and
        helpful your page is, the more users can revisit it. It is especially important when it
        comes to e-commerce, but every kind of business will benefit from an optimized site.
        Improving user experience is a part of a website’s conversion optimization.
      </p>
      <br />

      <h3>Mobile first</h3>
      <p>
        Responsive web design is a total must-have nowadays as most people also use mobile devices
        for browsing the Internet. Once your website is responsive it will automatically adjust
        itself to every size of a mobile device (smartphone, tablet). Pay attention to additional
        elements that appear on your website, such as web push notifications,{' '}
        <a
          href="https://www.poptin.com/blog/creative-website-popups-design-examples-inspiration/"
          rel="noopener noreferrer"
          target="_blank"
        >
          pop-ups
        </a>
        , chat boxes. If they can not be properly displayed on a mobile device, it is better to turn
        them off for smartphones and tablets as they can harm the mobile user experience.
      </p>
      <br />

      <h3>Speed loading</h3>
      <p>
        Your website needs to load fast, both desktop and mobile version, so you will not lose
        potential customers. If your page is loading for too long, even though you drive traffic,
        your website might not convert too well.{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.thinkwithgoogle.com/marketing-resources/data-measurement/mobile-page-speed-new-industry-benchmarks/"
        >
          Survey shows
        </Link>{' '}
        that 53% of mobile visitors leave the site after 3 seconds of loading. It is clearly one of
        the most significant factors of a resultful page. You can test website speed loading in
        tools such as{' '}
        <Link rel="noopener noreferrer nofollow" target="_blank" href="https://www.gtmetrix.com/">
          GTmetrix
        </Link>
        .
      </p>
      <br />

      <h3>Intuitive navigation</h3>
      <p>
        While designing a menu test it to find out if it is equally intuitive for other people.
        Every desired element by customers on the website should be easy to find. What matters is
        also the language. Think twice about terms that you use on your website. They should be
        clear and understandable.
      </p>
      <br />

      <h3>Keep it simple</h3>
      <p>
        Do not overwhelm your visitors with too many elements. Nowadays minimalism is a trend for
        website design. Add only one main call-to-action button on a subpage so that users can be
        sure what action you want them to take. Also, do not annoy visitors. It can be helpful to
        use pop-ups and web push notifications but use it wisely. Implement the right amount of
        additional elements on your website so that you will not distract your potential clients.
      </p>
      <br />

      <h3>Customer support</h3>
      <p>
        Internet users very often visit a website because they are looking for help from a certain
        company. A very good idea is to provide FAQs with the most popular issues. You should also
        enable finding your contact info easily. There can be a separate page for that but you can
        also add such information at the bottom of your website. Additionally, a trend today is a
        chatbox that automatically opens after a certain time spent on the page. it should be very
        friendly, you can invite visitors to a conversation with a question like “Hello :) I’m
        Maria. How can I help you?”. You can program a chatbot (with tools like{' '}
        <Link rel="noopener noreferrer" target="_blank" href="https://chatfuel.com/">
          Chatfuel
        </Link>
        ) which will answer the most common questions but remember that at some point you might need
        a real person to solve more complicated issues or discuss an offer. Nevertheless, time plays
        a huge role in customer support so using marketing automation tools can help you with
        meeting your customers&apos; expectations.
      </p>
      <br />

      <h3>GOLDEN TIP</h3>
      <p>
        User experience is an empirical field which requires surveys and research. Every company or
        organization has a different target group with different preferences. To find out how your
        visitors use your website, what are they struggling with, is it fully functional and
        intuitive, you should proceed with your own research. With online tools like{' '}
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="/signup?utm_source=blog&utm_campaign=optimize-your-website-like-a-pro"
        >
          LiveSession
        </Link>
        , you can record visitors’ sessions on your page, watch it and easily identify issues. You
        can also use it for improving customer support - once you get a complaint on your website
        you can better navigate your client, as you have access to his session and you can follow
        his actions on the page.
      </p>
      <br />

      <h2>Let your customers find you</h2>
      <p>
        Search engine optimization can be seen as an aspect of user experience because it determines
        whether it is easy to actually find your website on the Internet. Here you can find a few
        main elements that you should pay attention to when it comes to SEO:
      </p>
      <ul>
        <li>
          <strong>Content marketing</strong> - users are looking for valuable content on the
          Internet. Meet their needs and publish articles about things that matter for your
          potential customers. That way you can build a relationship with them but also improve the
          website’s visibility - make sure you use right heading (H1, H2, H3).{' '}
        </li>
        <li>
          <strong>Keywords</strong> - whatever you publish on your website, whether it is a blog
          post or a product description you should keep in mind your keywords and use them. Check
          out Google keyword planner to find out what might attract your audience.{' '}
        </li>
        <li>
          <strong>frontmatter description</strong> - it is the description displayed in the search
          results, just below the title and URL of the page. It should attract users to visit your
          website once they find it in search results.
        </li>
        <li>
          <strong>Alt text</strong> - is it displayed on the page instead of the image when it
          cannot be load. In the alternative description, you should briefly capture what is in the
          picture. Thanks to well-made alt text, the user knows what is in the picture that can not
          currently be loaded. Describing photos using alt texts is also important from the SEO
          point of view. Google algorithm is not able to read pictures but can read alt texts.
        </li>
        <li>
          <strong>Link building</strong> - use internal linking to other your pages, but also
          external linking. You can publish guest posts on blogs of other companies, but you might
          also consider investing in sponsored articles in media.{' '}
        </li>
      </ul>
      <br />
      <p>
        Website optimization can be challenging but we hope that with the aforementioned handy tips
        you can increase your page’s visibility and user experience. For the most, remember that
        optimization is not a one time action. It is a process of constant website’s performance
        analyzing and improving. Good luck!{' '}
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Optimize your website like a pro',
  url: '/blog/optimize-your-website-like-a-pro',
  description: `To
  beat your competitors you should have an outstanding website which
  also would be easy to find.`,
  author,
  img: cover,
  date: '2019-07-02',
  category: 'growth',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Articles',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial="Today’s business world is competitive and is growing dynamically. To beat your competitors you should have an outstanding website which also would be easy to find. When we talk about optimization very often marketers minds go to search engine optimization, nevertheless we can not forget about user experience. Find out how to do it like a pro and boost your website’s performance!"
    img={frontmatter.img}
  />
);
